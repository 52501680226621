.shipping {
  background: #f4f4f5;
  .sectionsShipping {
    padding: 50px 0 0;
    .head {
      padding: 100px 0;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: url('../../components/SectionsShipping/bg-remessa.png') center right no-repeat;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0px;
        opacity: .7;
      }
    }
    .content {
      background: #fff;
      padding: 50px 0;

      .aboutShipping {
        display: flex;
        margin: 40px 0;
      }

      ul.list-squad {
        display: flex;
        margin: 0;
        flex-wrap: wrap;
        // align-items: center;
        justify-content: center;
        li {
          width: 305px;
          padding: 15px;
          border: 1px solid rgb(211, 211, 211);
          min-height: 300px;
          // display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          background: #fff;
          cursor: pointer;
          text-align: center;
          margin: -1px;
          transition: all .2s;
          &:nth-child(1) {
            i.icon {
              background-position: 0px -141px;
              width: 49px;
            }
          }
          &:nth-child(2) {
            i.icon {
              background-position: 0px -332px;
              width: 120px;
            }
          }
          &:nth-child(3) {
            i.icon {
              background-position: -45px -141px;
            }
          }
          &:nth-child(4) {
            i.icon {
              background-position: -100px -141px;
            }
          }
          &:nth-child(5) {
            i.icon {
              background-position: -161px -141px;
            }
          }
          &:nth-child(6) {
            i.icon {
              background-position: -218px -141px;
            }
          }
          i.icon {
            width: 60px;
            height: 60px;
            background: url('../../components/Commons/sprite.png');
            display: block;
            margin: 0 auto;
          }
          .p {
             margin: 10px 0;
             font-family: Poppins;
             line-height: 22px;
          }
          & > .span {
            font-size: 15px;
            font-family: Poppins;
            line-height: 20px;
            display: block;
            padding: 0 15px;
            span {
              font-size: 11px;
              display: none;
            }
          }
          &:hover {
            border-color: #981a1a;
            z-index: 1;
            transition: all .2s;
            .span {
              span {
                display: block;
              }
            }
            button {
              opacity: 1;
            }
          }
          button {
            opacity: 0;
            transition: all .2s;
          }
        }
      }
    }
    .form {
      padding: 50px;
      background: #222d5e;
      .h4, .p {
        color: #fff;
      }
    }
  }
  @media(max-width: 768px) {
    .sectionsShipping {
      .head {
        &:before {
          display: none;
        }
      }
      .content {
        ul.list-squad {
          li {
            width: 80%;
            button {
              opacity: 1;
            }
            .span {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
