.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  .center {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}