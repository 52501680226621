.CoinPrice {
  width: 170px;
  background: #fff;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: 90px;
  padding: 15px;
  margin: 15px 10px;
  .top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      .p {
        font-size: 14px;
        color: #6e7174;
        margin: 0;
        line-height: 20px;
      }
      .h3 {
        font-size: 18px;
        font-family: Lato;
        margin: 0;
        line-height: 20px;
      }
    }
  }
  .bottom {
    .price {
      color: #267df4;
      margin: 0;
      text-align: center;
      line-height: 20px;
      font-family: Lato;
      font-weight: 600;
    }
  }
}

.coinForm {
  .loader {
    z-index: 6;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.05);
  }
}

@media(max-width: 768px) {

  .list-coins {
    justify-content: space-between !important;
      .CoinPrice {
        width: 45%;
        margin: 20px 0 !important;
      }
  }
}
