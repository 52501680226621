.paperMoney {
  background: #f4f4f5;
  .sectionsPaper {
    .head {
      padding: 180px 0 80px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: url('./paperMoney.svg') center bottom no-repeat;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0px;
        opacity: .8;
      }
      .h2 {
      }
      .whitelabel {
        margin-top: 50px;
        box-shadow: none;
        overflow: visible !important;
        .span {
          font-size: 12px;
        }
      }
    }
    > .content {
      padding: 100px 0;
      background: #222d5e;
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 200px;
        background: #c82343;
        transform: rotate(6deg);
        left: -50%;
        bottom: -130px;
      }
      .h4 {
        color: #fff;
      }
      
      .why-buy {
        width: 100%;

        ul.list-squad {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 30px 0;
        
          li {
            width: 300px;
            padding: 30px;
            margin: 5px;
            border: 1px solid rgba(255, 255, 255, 0.33);
            border-radius: 30px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all .3s;
            &:nth-child(1) {
              .icon {
                background-position: -117px -405px;
                width: 80px;
              }
            }
            &:nth-child(2) {
              .icon {
                background-position: -65px -401px;
              }
            }
            &:nth-child(3) {
              .icon {
                background-position: -2px -402px;
                width: 60px;
              }
            }
            .icon {
              height: 50px;
              width: 50px;
              background: url('../../components/Commons/sprite.png') no-repeat;
              margin-bottom: 10px;
            }
            .p {
              color: #fff;
              font-family: Poppins;
            }
            .span {
              color: #fff;
              font-family: Poppins;
              font-size: 14px;
              line-height: 21px;
            }
            &:hover {
              border-color: #fff;
            } 
          }
        }
      }
    }
    .delivery {
      padding: 50px 0;
      background: #fff;
      a {
        text-decoration: none;
      }
      .delivery-button-lg {
        border-radius: 30px;
        border: 1px solid #ccc;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .icon {
          transform: scale(.8);
          width: 155px;
          height: 130px;
          background: url('../../components/Commons/sprite.png') 0px -203px no-repeat;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .h4 {
            margin: 15px 0;
          }
          .p {
            margin: 0;
          }
        }
      }
    }
    .card-travel {
      padding: 50px 0;
      background: #343e6d;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background: url('./earth.jpg') right no-repeat;
        filter: blur(3px);
        -webkit-filter: blur(3px);
        width: 120%;
        transform: translate(-20px, 0);
        height: 120%;
        left: 0;
        bottom: 0px;
        opacity: .4;
      }
      .h2, .p, .h4 {
        color: #fff;
      }
      .travel-box {
        padding: 30px;
        .h3 {
          margin: 0 0 15px;
        }
        .list {
          margin: 0;
          padding-left: 15px;
          li {
            list-style: circle;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .paperMoney {
    .sectionsPaper {
      .content {
        &:after {
          left: -20%;
          bottom: -180px;
        }
      }
      .delivery-button-lg {
        flex-direction: column;
        align-items: center;
      }
      .buy-now {
        margin-bottom: 20px;
      }
    }
  }
}