.howItWorks {
  background: #f4f4f5;
  padding: 90px 0 50px;
}


.coinsRealTime {
  background: #222d5e;
  padding: 100px 0 50px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100px;
    background: #c82343;
    top: -76px;
    transform: rotate(-2deg);
    left: -30px;
  }
  
  .bg-header {
    opacity: .1;
  }
  
  .list-coins {
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .bottom {
    position: relative;
    z-index: 5;
    .p {
      color: #fff;
      font-size: 16px;
      margin: 0;
      a {
        color: #7caced;
        text-decoration: underline;
      }
    }
  }

  .defaultTitle {
    position: relative;
    z-index: 5;
    .h2 {
      color: #fff;
      line-height: 50px
    }
    .p {
      color: #fff;
      margin: 0;
    }
    .h4 {
      color: #fff;
    }
  }
}

.sectionShipping {
  padding: 100px 0 200px;
  position: relative;
  overflow: hidden;
  background: #fff;

  .bg-shipping {
    background: #fff url('../../components/Commons/shippingImg.png') no-repeat right center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: -250px;
    z-index: 0;
    opacity: .8;
  }
}

.list-default {
  padding-left: 20px;
  
  li {
    list-style: circle;
    font-size: 18px;
    line-height: 25px;
    color: #8c8f94;
    font-family: Lato;
  }
}

.form-whitelabel {

  .two-items.br {
    justify-content: flex-start !important;
  }
  
  // .two-items.br .defaultField {
  //   width: auto;
  // }
  .flex {
    display: flex;
    flex-direction: column-reverse;
    transition: .2s all ease;
  }
  
  .flex.receive {
    flex-direction: column;
  }
  
  .brCoin {
    width: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .icon {
      width: 35px;
    }
  
  }
}
.tabs {
  .nav {
    position: relative;
    position: absolute;
    top: -33px;
    height: 45px;
    z-index: 5;
  }

  .nav {
    .title {
      border-radius: 4px 4px;
      margin-right: 5px;
      cursor: pointer;
      font-family: Lato;
      font-size: 15px;
      height: 37px;
      align-items: baseline;
      border: none;
      color: rgb(59, 59, 59);
      transition: all ease .2s;
      padding: 5px 15px;
      background: rgb(235, 235, 235);
      &:hover {
        border-color: #000;
        background: #f0f0f0;
      }
    }
  }

  &.papel {
    .nav {
      .title {
        &:nth-child(1) {
          color: #fff;
          font-size: 16px;
          background: #b00909;
          box-shadow: 0px -15px 15px -15px rgba(0, 0, 0, 0.22);
        }
      }
    } 
  }
  &.remessa {
    .nav {
      .title {
        &:nth-child(2) {
          color: #fff;
          font-size: 16px;
          background: #b00909;
          box-shadow: 0px -15px 15px -15px rgba(0, 0, 0, 0.22);
        }
      }
    } 
  }

}
.remessa {
  .defaultField {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
  }

  [class*="MuiInputLabel-outlined"] {
    background: #fff !important;
  }
}

@media(max-width: 768px) {

  .sectionShipping {
    padding: 100px 0;
    .bg-shipping {
      opacity: .2;
    }
  }

}