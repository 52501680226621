@import url('https://fonts.googleapis.com/css?family=Poppins:300,500,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,900');

* {
  box-sizing: border-box;
}

html, body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #f4f4f5;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

h1, h2, h3, h4, h5, h6, p, button {
  font-weight: 400;
  font-family: 'Poppins', 'Lato', sans-serif;
}

.h1 {
  font-size: 62px;
  line-height: 72px;
}

.h2 {
  font-size: 50px;
  line-height: 52px;
}

.h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  margin-bottom: 46px;
}

h4 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 25px;
}

p.subtitle {
  font-size: 12px;
  letter-spacing: 0.1em;
  font-weight: 700;
  text-transform: uppercase;
}

p {
  font-size: 18px;
  line-height: 28px;
  color: #8c8f94;
}

.p {
  font-size: 20px;
  line-height: 28px;
  color: #595c63;
  font-family: Lato;
}

a {
  color: #267df4;
  text-decoration: none !important;
}

a:focus {
  outline: none;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-800 {
  font-weight: 800;
}

ul {
  padding-left: 0px;
}

ul > li {
  list-style: none;
}

.btn {
  font-size: 16px;
  font-weight: 400;
  padding: 9px 16px;
  text-decoration: none;
  color: #26272d;
  border: 1px solid rgba(38, 39, 45, 0.1);
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.btn:active, .btn:focus {
  outline: none;
  box-shadow: none;
}

.btn-lg {
  padding: 14px 48px;
}

.btn-blue {
  background-color: #267df4;
  color: white;
}

.btn-green {
  background-color: #6fc754;
  color: white;
}

.btn-dark {
  background-color: #131315;
  color: white;
}

.btn-blue:hover, .btn-blue:focus,
.btn-green:hover, .btn-green:focus,
.btn-dark:hover, .btn-dark:focus {
  color: #ffffff;
}

#main-nav {
  position: fixed;
  left: 0;
  top: 40px;
  width: 100%;
  padding: 25px 0 10px;
  font-size: 16px;
  z-index: 10;
  background-color: #f4f4f5;
  webkit-transition: all 0.37s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.37s cubic-bezier(0, 0, 0.58, 1);
  @media(max-width:768px) {
    top: 0;
  }
}

#main-nav .navigation {
  color: #26272d;
  text-decoration: none;
  position: absolute;
  margin-top: 23px;
  z-index: 2;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}

#main-nav .navigation:hover {
  color: #267df4 !important;
}

#main-nav ul {
  padding: 0;
}

#main-nav ul li {
  display: inline-block;
  margin-right: 40px;
  padding: 20px 0;
}

#main-nav ul li a {
  color: #26272d;
  text-decoration: none;
}

#main-nav ul li a:hover {
  color: #267df4;
}

#main-nav ul li .help {
  color: rgba(38, 39, 45, 0.4);
}

#main-nav ul li .btn-blue {
  color: #ffffff;
}

#main-nav ul li .btn-blue:hover {
  color: #ffffff;
}

#main-nav ul li:last-child {
  margin-right: 0;
}

#main-nav ul.left {
  text-align: left;
  margin-left: 50px;
}

#main-nav ul.left li {
  display: inline-block;
  padding: 20px 0;
}

#main-nav ul.left li:first-child {
  margin-right: 30px;
}

#main-nav ul.right {
  text-align: right;
}

#main-nav .logo {
  display: inline-block;
  margin-top: -20px;
}

#main-nav #slide_out_menu {
  position: fixed;
  display: block;
  width: 380px;
  max-width: 650px;
  height: 100%;
  left: -380px;
  top: 0;
  padding: 40px;
  z-index: 50;
  background: #26272d;
  transition: left 0.7s cubic-bezier(0.545, 0, 0.05, 1);
}

#main-nav #slide_out_menu .menu-close {
  position: absolute;
  color: #ffffff;
  right: 20px;
  top: 52px;
}

#main-nav #slide_out_menu ul {
  margin-top: 40px;
}

#main-nav #slide_out_menu ul li {
  display: block;
  margin: 10px 0;
}

#main-nav #slide_out_menu ul li a {
  color: #ffffff;
}

#main-nav #slide_out_menu ul li a:hover {
  color: #267df4;
}

#main-nav #slide_out_menu ul li .btn-blue:hover {
  color: #ffffff;
}

#main-nav #slide_out_menu ul li:last-child {
  margin-top: 20px;
}

#main-nav #slide_out_menu .slide_out_menu_footer {
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 20px 40px 25px;
}

#main-nav #slide_out_menu .slide_out_menu_footer .more-info p {
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

#main-nav #slide_out_menu .slide_out_menu_footer .socials {
  margin: 18px 0 0;
}

#main-nav #slide_out_menu .slide_out_menu_footer .socials li {
  display: inline-block;
  margin: 0 20px 0 0;
}

#main-nav #slide_out_menu .slide_out_menu_footer .socials li a {
  color: rgba(255, 255, 255, 0.8);
  transition: all .2s ease-in-out;
}

#main-nav #slide_out_menu .slide_out_menu_footer .socials li a:hover {
  color: #ffffff;
}

#main-nav #slide_out_menu .slide_out_menu_footer .socials li:last-child {
  margin-right: 0;
}

#main-nav #slide_out_menu.xs-screen {
  overflow-y: auto;
}

#main-nav #slide_out_menu.xs-screen ul {
  margin-top: 40px;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer {
  position: relative;
  padding: 0;
  margin-top: 40px;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .more-info p {
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .socials {
  margin: 18px 0 0;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .socials li {
  display: inline-block;
  margin: 0 20px 0 0;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .socials li a {
  color: rgba(255, 255, 255, 0.8);
  transition: all .2s ease-in-out;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .socials li a:hover {
  color: #ffffff;
}

#main-nav #slide_out_menu.xs-screen .slide_out_menu_footer .socials li:last-child {
  margin-right: 0;
}

#main-nav #slide_out_menu.open {
  left: 0;
}

#main-nav.active {
  background-color: #ffffff;
  padding: 24px 0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

#features {
  padding: 120px 0;
}

#features .feature {
  padding: 0px 38px;
}

#features .feature .f-icon {
  margin-bottom: 48px;
}

#features .feature .f-icon i {
  font-size: 42px;
  color: #267df4;
}

#iphone-feature {
  position: relative;
  padding: 240px 0 330px;
}

#iphone-feature .btns-container {
  margin-top: 62px;
}

#iphone-feature .btns-container a {
  margin-right: 20px;
  margin-bottom: 18px;
}

#iphone-feature .btns-container a i {
  margin-left: 50px;
  font-size: 14px;
}

#iphone-feature .btns-container a:last-child {
  margin-right: 0;
}

#iphone-feature .iphone-showcase {
  position: absolute;
  bottom: 110px;
  right: 50%;
}

#map-feature {
  padding: 120px 0 140px;
  position: relative;
  overflow: hidden;
}

#map-feature .partners {
  margin-top: 52px;
}

#map-feature .partners .title {
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 12px;
}

#map-feature .partners ul {
  margin: 26px 0 0;
}

#map-feature .partners ul li {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 15px;
}

#map-feature .partners ul li:last-child {
  margin-right: 0;
}

#map-feature .map-showcase {
  position: absolute;
  bottom: 110px;
  left: 50%;
}

#iphone-screens {
  padding: 140px 0 800px;
  position: relative;
  background-color: #f4f4f5;
  overflow: hidden;
}

#iphone-screens h2 {
  margin-bottom: 0;
}

#iphone-screens hr {
  width: 50px;
  height: 2px;
  margin: 40px auto;
  background-color: #267df4;
}

#iphone-screens .images-list {
  width: 100%;
  position: relative;
}

#iphone-screens .images-list li {
  position: absolute;
  width: 320px;
  top: 92px;
  box-shadow: 10px 10px 16px 0px rgba(61, 61, 62, 0.15);
}

#iphone-screens .images-list li img {
  max-width: 100%;
}

#iphone-screens .images-list li:nth-child(1) {
  left: 50%;
  margin-left: -880px;
}

#iphone-screens .images-list li:nth-child(2) {
  left: 50%;
  margin-left: -530px;
}

#iphone-screens .images-list li:nth-child(4) {
  right: 50%;
  margin-right: -530px;
}

#iphone-screens .images-list li:nth-child(5) {
  right: 50%;
  margin-right: -880px;
}

#iphone-screens .images-list li.main-img {
  width: 395px;
  top: 0;
  left: 50%;
  margin-left: -193px;
  box-shadow: none;
}

#iphone-screens .images-list li.main-img img {
  max-width: 100%;
}

#ready-to-buy {
  padding: 120px 0 130px;
  background-color: #f4f4f5;
}

#ready-to-buy form {
  margin-bottom: 66px;
}

#ready-to-buy .btn i {
  font-size: 12px;
  margin-left: 18px;
}

#ready-to-buy .btn-dark {
  padding: 9px 28px;
}

#ready-to-buy .btn-dark i {
  font-size: 32px;
  display: inline-block;
  margin-left: 0;
  margin-right: 18px;
}

#ready-to-buy .btn-dark span {
  font-size: 12px;
  display: inline-block;
  text-align: left;
}

#ready-to-buy .btn-dark span b {
  font-size: 22px;
  line-height: 18px;
}

#customers {
  padding: 120px 0;
}

#customers i {
  color: #8c8f94;
  font-size: 22px;
}

#customers .star-rating {
  margin-top: 17px;
}

#customers .star-rating .gold-star {
  color: #f2bc3b;
}

#customers .customer-name {
  margin-top: 56px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #26272d;
}

#customers .testimonial {
  padding-top: 174px;
}

#customers .carousel-indicators {
  position: absolute;
  top: 0;
  bottom: auto;
  width: 100%;
  z-index: 5;
  left: 0;
  margin-left: 0;
}

#customers .carousel-indicators li {
  width: 130px;
  height: 130px;
  border-radius: 0;
  margin: 0;
  border: none;
  opacity: .2;
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transition: all 0.97s cubic-bezier(0.545, 0, 0.05, 1);
}

#customers .carousel-indicators li img {
  position: relative;
  border-radius: 100%;
  z-index: 2;
  box-shadow: 0px 12px 30px 0px rgba(92, 95, 101, 0.18);
}

#customers .carousel-indicators li:first-child {
  float: left;
}

#customers .carousel-indicators li:first-child img {
  box-shadow: 10px 12px 30px 0px rgba(92, 95, 101, 0.18);
}

#customers .carousel-indicators li:last-child {
  float: right;
}

#customers .carousel-indicators li:last-child img {
  box-shadow: -10px 12px 30px 0px rgba(92, 95, 101, 0.18);
}

#customers .carousel-indicators li.active {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

#customers .carousel-control.left,
#customers .carousel-control.right {
  background: none;
  width: 41px;
  height: 41px;
  top: 45px;
  line-height: 41px;
}

#customers .carousel-control.left {
  left: -100px;
}

#customers .carousel-control.right {
  right: -100px;
}

#plan {
  padding: 160px 0 190px;
  background-color: #f4f4f5;
}

#plan .subtitle {
  color: #26272d;
}

#plan .toggle-container {
  margin-bottom: 70px;
}

#plan .toggle-container .switch-toggles {
  position: relative;
  width: 262px;
  height: 50px;
  border: 1px solid rgba(38, 39, 45, 0.2);
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

#plan .toggle-container .switch-toggles .individual, #plan .toggle-container .switch-toggles .company {
  position: relative;
  width: 130px;
  float: left;
  padding: 13px 10px;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
  color: #8c8f94;
  transition: color 0.7s cubic-bezier(0.545, 0, 0.05, 1);
}

#plan .toggle-container .switch-toggles .individual.active,
#plan .toggle-container .switch-toggles .company.active {
  color: #26272d;
}

#plan .toggle-container .switch-toggles:after {
  position: absolute;
  content: "";
  width: 122px;
  height: 42px;
  background: #ffffff;
  left: 3px;
  top: 3px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  box-shadow: 2.5px 4.33px 20px 0px rgba(61, 61, 62, 0.059);
  -moz-box-shadow: 2.5px 4.33px 20px 0px rgba(61, 61, 62, 0.059);
  -webkitbox-shadow: 2.5px 4.33px 20px 0px rgba(61, 61, 62, 0.059);
  transition: all 0.7s cubic-bezier(0.545, 0, 0.05, 1);
}

#plan .toggle-container .switch-toggles.active:after {
  left: 135px;
  top: 3px;
}

#plan .info {
  font-size: 14px;
  margin-top: 24px;
}

#plan .price-table {
  position: relative;
  width: 340px;
  max-width: 50%;
  float: left;
  padding: 20px 0;
}

#plan .price-table .table-inner {
  background-color: #ebebed;
  padding: 40px 15px 50px;
}

#plan .price-table .table-inner h3 {
  margin: 0 auto 28px;
}

#plan .price-table .table-inner .price {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #26272d;
  padding: 9px 18px;
  border: 1px solid rgba(38, 39, 45, 0.2);
  display: inline-block;
  margin-bottom: 40px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

#plan .price-table .table-inner .phrase {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 38px;
}

#plan .price-table .table-inner .feature-list li {
  font-size: 16px;
  line-height: 24px;
  color: #26272d;
  margin-bottom: 12px;
}

#plan .price-table .table-inner .feature-list li i {
  color: #6fc754;
}

#plan .price-table .table-inner .feature-list li:last-child {
  margin-bottom: 34px;
}

#plan .price-table .table-inner .btn {
  width: 210px;
  max-width: 100%;
}

#plan .price-table .table-inner .btn-blue {
  background-color: transparent;
  border: 1px solid #267df4;
  color: #267df4;
}

#plan .price-table.highlighted {
  z-index: 2;
  padding: 0;
  box-shadow: 15px 25px 50px 0px rgba(92, 95, 101, 0.2);
  -moz-box-shadow: 15px 25px 50px 0px rgba(92, 95, 101, 0.2);
  -webkit-box-shadow: 15px 25px 50px 0px rgba(92, 95, 101, 0.2);
}

#plan .price-table.highlighted .table-inner {
  background-color: #ffffff;
  padding: 60px 15px 70px;
}

#plan .price-table.highlighted .btn-blue {
  background-color: #267df4;
  color: #ffffff;
}

#plan #price_tables .individual, #plan #price_tables .company {
  position: relative;
  width: 100%;
  opacity: 0;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: opacity 0.97s, -webkit-transform 0.97s;
  transition: opacity 0.97s, transform 0.97s;
  transition: opacity 0.97s, transform 0.97s, -webkit-transform 0.97s;
  transition-timing-function: cubic-bezier(0.545, 0, 0.05, 1);
}

#plan #price_tables .individual .price-table, #plan #price_tables .company .price-table {
  display: none;
}

#plan #price_tables .individual.active, #plan #price_tables .company.active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

#plan #price_tables .individual.active .price-table, #plan #price_tables .company.active .price-table {
  display: block;
}

#subscribe {
  padding: 90px 0 50px;
}

#subscribe .btn {
  padding-left: 34px;
  padding-right: 34px;
}

#subscribe .promise {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}

#footer {
  color: #8c8f94;
  font-size: 18px;
  background: #f6f6f8;
  
  .footer-container {
    padding-top: 20px;
    padding-bottom: 40px;

    .footer-links {
      padding-top: 40px;
      li {
        text-align: right;
      }
      li + li {
        margin: 0;
      }
    }
  }
  .about-we {
    p {
      margin: -10px 0 0;
    }
  }
}

#footer .copyright-container {
  padding-top: 20px;
  padding-bottom: 25px;
}

#footer .logo {
  margin-bottom: 36px;
}

#footer p {
  font-size: 15px;
  line-height: 25px;
  font-weight: 300;
  margin: 10px 0;
  span {
    font-size: 13px;
    margin-top: -10px;
    display: block;
  }
}

#footer .app_download img {
  margin-top: 6px;
}

#footer ul li .title {
  font-weight: 500;
  letter-spacing: .5px;
  color: #0f276a;
  font-size: 20px;
}

#footer ul li a {
  color: #8c8f94;
  text-decoration: none;
  line-height: 30px;
}

#footer ul li a:hover {
  color: #26272d;
}

.socials {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  li {
    font-size: 20px;
    margin: 0;
    margin-right: 20px;
    a {
      color: rgba(19, 19, 21, 0.3);
    }
  }
}



#footer .socials li:last-child {
  margin-right: 0;
}

#footer .more-info {
  line-height: 24px;
  font-size: 14px;
}

#footer .made-by {
  font-size: 9px;
  font-weight: 700;
  line-height: 22px;
  color: #26272d;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

#footer .made-by a img {
  margin-top: -8px;
  margin-left: 8px;
}


.copy {
  background: #fff;
  padding: 20px 0;
  .p {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 14px;
      color: rgb(148, 150, 156);
      font-family: Poppins;
    }
  }
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
    li {
      align-items: center;
      display: flex;
    }
    .icon {
      width: 160px;
      height: 70px;
      background: url('../components/Commons/sprite.png') no-repeat;
      display: block;
      transform: scale(.8);
      opacity: .8;
      &.icon-frente {
        background-position: 0px -549px;
        width: 170px;
      }
      &.icon-banco {
        background-position: 0px -615px;
        width: 205px;
        height: 55px;
      }
      &.icon-banco2 {
        background: url('../components/Footer/cotacao.png') no-repeat;
        width: 205px;
        height: 55px;
      }
      &.icon-banco3 {
        background: url('../components/Footer/sagitur.png') no-repeat;
        width: 205px;
        height: 55px;
      }
      &.icon-fair {
        background-position: 0px -670px;
        width: 170px;
        height: 55px;
      }
    }
  }
}



@media(max-width: 768px) {
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 !important;
  }

  .footer-links p, .footer-links li, .footer-links a {
    text-align: center;
    width: 100%;
    display: block;
  }

  ul.socials {
    justify-content: center !important;
  }

  #footer .footer-container {
    padding: 40px 30px 0;
  }

}

@media only screen and (max-width: 992px) {
  /*** Main Nav ***/
  #main-nav .col-md-4:first-child,
  #main-nav .col-md-4:last-child {
    display: none;
  }
  #main-nav .logo {
    padding-top: 0px;
  }
  #main-nav #slide_out_menu {
    position: fixed;
    display: block;
    width: 300px;
    height: 100%;
    left: -300px;
  }
  #main-nav #slide_out_menu .menu-close {
    top: 41px;
  }
  /*** Features ***/
  #features {
    padding: 100px 0;
  }
  #features .feature-item {
    margin-bottom: 80px;
  }
  #features .feature-item:last-child {
    margin-bottom: 0;
  }
  #features .feature {
    padding: 0px 0px;
  }
  #features .feature .f-icon {
    margin-bottom: 48px;
  }
  #features .feature .f-icon i {
    font-size: 42px;
    color: #267df4;
  }
  /*** iPhone Feature ***/
  #iphone-feature {
    padding: 80px 0 60px;
  }
  #iphone-feature .iphone-showcase {
    position: relative;
    bottom: auto;
    right: auto;
    padding: 0 15px;
    margin-top: 64px;
  }
  #iphone-feature .iphone-showcase img {
    width: 100%;
  }
  /*** Map Feature ***/
  #map-feature {
    padding: 80px 0 100px;
  }
  #map-feature .map-showcase {
    position: relative;
    bottom: auto;
    left: auto;
    padding: 0 15px;
    margin-top: 64px;
  }
  #map-feature .map-showcase img {
    width: 100%;
  }
  /*** iPhone Screens ***/
  #iphone-screens {
    padding: 120px 0 620px;
  }
  #iphone-screens .images-list li {
    position: absolute;
    width: 240px;
    top: 68px;
  }
  #iphone-screens .images-list li img {
    max-width: 100%;
  }
  #iphone-screens .images-list li:nth-child(1) {
    margin-left: -680px;
  }
  #iphone-screens .images-list li:nth-child(2) {
    margin-left: -410px;
  }
  #iphone-screens .images-list li:nth-child(4) {
    margin-right: -410px;
  }
  #iphone-screens .images-list li:nth-child(5) {
    margin-right: -680px;
  }
  #iphone-screens .images-list li.main-img {
    width: 295px;
    margin-left: -148px;
  }
  /*** Customers ***/
  #customers .carousel-control.left,
  #customers .carousel-control.right {
    display: none;
  }
  /*** Plan ***/
  #plan {
    padding: 120px 0 150px;
  }
  #plan #price_tables .individual.active, #plan #price_tables .company.active {
    margin-top: 80px;
  }
  #plan .toggle-container .switch-toggles .individual, #plan .toggle-container .switch-toggles .company {
    margin-top: 0;
  }
  /*** Footer ***/
  #footer .socials {
    margin-bottom: 60px;
  }
  #footer .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-links:last-child {
    margin-bottom: 0;
  }
  /*** Copyright Container ***/
  .copyright-container .text-right {
    text-align: left;
    margin-top: 22px;
  }
}

@media only screen and (max-width: 768px) {
  /*** iPhone Screens ***/
  #iphone-screens {
    padding: 120px 0 460px;
  }
  #iphone-screens .images-list li {
    position: absolute;
    width: 178px;
    top: 52px;
  }
  #iphone-screens .images-list li img {
    max-width: 100%;
  }
  #iphone-screens .images-list li:nth-child(1) {
    margin-left: -502px;
  }
  #iphone-screens .images-list li:nth-child(2) {
    margin-left: -304px;
  }
  #iphone-screens .images-list li:nth-child(4) {
    margin-right: -304px;
  }
  #iphone-screens .images-list li:nth-child(5) {
    margin-right: -502px;
  }
  #iphone-screens .images-list li.main-img {
    width: 220px;
    margin-left: -110px;
  }
}

@media only screen and (max-width: 575px) {

  /*** Ready to Buy  ***/
  #ready-to-buy .form-group .btn,
  #subscribe .form-group .btn {
    position: relative;
    margin-top: 14px;
  }
  /*** Customers ***/
  #customers {
    padding: 120px 0;
  }
  #customers .carousel-indicators li {
    width: 90px;
    height: 90px;
  }
  /*** Plan ***/
  #plan {
    padding: 120px 0 100px;
  }
  #plan .price-table {
    width: 340px;
    max-width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
    float: none;
  }
  /*** Footer ***/
  #footer .footer-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #footer .footer-links {
    width: 100%;
  }
}

.pt10 {
  padding-top: 10px;  
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
.pt50 {
  padding-top: 50px;
}


.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb50 {
  padding-bottom: 50px;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}


.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb50 {
  margin-bottom: 50px;
}

.uppercase {
  text-transform: uppercase;
}

.defaultTitle {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li {
      background: #7a7c83;
      padding: 1px 10px;
      font-size: 14px;
      color: #fff;
      margin: 0 5px 5px 0;
      border-radius: 10px;
    }
  }
}

.label {
  background: transparent !important;
  border-radius: 6px;
  margin: 15px 0 0;
  text-align: center;
  &.error {
    .p {
      color: #aa3153;
      border-color: #aa3153;
    }
  }
  &.success {
    .p {
      border-color: #47bc66;
      color: #47bc66;
    }
  }
  .p {
    border: 2px solid #ccc;
    font-size: 16px;
    color: #000;
    margin: 0;
    padding: 15px;
  }
}