.sectionsPolicy {
  padding: 150px 0 0;
  
  .head {
    padding: 50px 0 0;
    .icon-policy {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      .icon {
        width: 155px;
        height: 75px;
        display: block;
        background: url('../../components/Commons/sprite.png') -1px -470px;
      }
    }
    ul.list {
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      li {
        font-size: 18px;
        text-transform: uppercase;
        margin: 0 0 12px;
        color: #595c63;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          display: block;
          width: 40px;
          height: 40px;
          background: url('../../components/Commons/sprite.png') -220px -390px;
          margin-right: 15px;
        }
        &:nth-child(1) {
          &:before {
            background-position: -220px -280px;
          }
        }
        &:nth-child(2) {
          &:before {
            background-position: -220px -335px;
          }
        }
        &:nth-child(3) {
          &:before {
            background-position: -220px -390px;
          }
        }
        &:nth-child(4) {
          &:before {
            background-position: -220px -440px;
          }
        }
        span {
          font-size: 14px;
        }
      }
    }

  }
  .footer {
    padding: 100px 0;
    background: #0f276a;
    .p {
      color: #fff;
    }
  }
}