.delivery {

  .sectionsDelivery {
    padding-top: 200px;
    background: #f4f4f5;

    .icon-delivery {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .icon {
        width: 155px;
        height: 130px;
        display: block;
        background: url('../../components/Commons/sprite.png') -1px -203px;
      }
    }

    .politics {
      background: #222d5e;
      padding: 60px 0;
      border-top: 10px solid #c82343;
      .h3 {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    .list-politics {
      color: #fff;
      margin: 0px;
      li {
        margin: 20px 0 0;
        padding: 0 0 20px;
        font-size: 18px;
        font-family: Lato;
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
        b {
          text-transform: uppercase;
          padding-left: 35px;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          background: #c82243;
          transform: rotate(45deg);
          top: 9px;
          left: 3px;
        }    
      }
    }
    .reserva {
      padding: 0;
      .phones {
        align-items: flex-end;
      }
    }
  }
}