.preHeader {
  background: #212d5e;
  height: 40px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 15;

  .flex {
    display: flex;
    align-items: center;
  }
  
  p {
    font-size: 14px;
    margin: 0;
    padding: 5px 0;
    color: #fff;
  }
  
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #fff;
    }
  }
  
  .socials {
    a {
      color: #fff;
    }
  }

  .icon {
    margin: 0 5px 0 20px;
  }
  @media(max-width:768px) {
    display: none;
  }
}