.button {
  height: 45px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  text-transform: none !important;
  outline: none !important;
  &.primary {
    background-color: #c82343 !important;
    span {
      color: #fff;
      font-size: 17px;
    }
    &.border {
      span {
        color: #c82343 !important;
      }
      background: transparent !important;
      border: 2px solid #c82343 !important;
    }
  }
  &.secundary {
    // background-color: #267df4 !important;
    background-color: #3455d2 !important;
    span {
      color: #fff;
      font-size: 17px;
    }
    &.border {
      span {
        color: #c82343 !important;
      }
      background: transparent !important;
      border: 2px solid #fff !important;
    }
  }
}


.large {
  height: 55px;
}

.success {
  background: #b00909 !important;
  border-radius: 4px;
  span {
    color: #fff;
    font-size: 20px;
  }
}

.full {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}