.wpp {
  display: flex;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  align-items: flex-end;

  &.black {
    color: #000;
    .icon, span, p {
      color: #000;
    }
  }

  .icon {
    font-size: 33px;
    color: #fff;
    margin-left: 10px;
  }

  span {
    font-size: 12px;
    line-height: 12px;
  }

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 17px;
  }  
}