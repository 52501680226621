.cards {
  display: flex;
  min-height: 420px;
  padding: 65px 0 40px;
}

.ProductCard {
  border: 0;
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.22);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eff0f1;
  cursor: pointer;
  position: relative;
  transition: all 0.37s;

  .image {
    height: 80px;
    overflow: hidden;
    border-radius: 7px 7px 0px 0px;
    img {
      width: 100%;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    padding: 30px 0 15px;
    .h3 {
      font-family: Lato;
      color: #84878d;
      font-weight: 500;
      margin: 0;
      font-size: 23px;
    }
  }

  .flag {
    display: flex;
    justify-content: center;
    padding: 5px 0 20px;
    .item {
      border: 1px solid #b4acac;
      padding: 5px 20px;
      border-radius: 30px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 1px;
      font-family: Lato;
      color: #000;
      text-align: center;
      display: block;
    }
  }

  .description {
    display: flex;
    justify-content: center;
    padding: 0 20px 25px;
    .p {
      width: 100%;
      margin: 0;
      font-size: 15px;
      line-height: 23px;
      font-family: Lato;
      text-align: center;
    }
  }

  .actions {
    padding: 15px 15px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    display: none
  }
  &:hover {
    background: #fff; 
    .image {
      height: 120px;
    }
    .description {
      padding-bottom: 15px;
    }
    .actions {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }

  & * {
    transition: all 0.37s;
  }
} 

.simpleCard {
  padding: 35px;
  box-shadow: -15px 15px 30px -5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #fff;
  .h3 {
    font-size: 26px;
    font-family: Lato;
    font-weight: 300;
    margin: 0;
  }
}


@media(max-width: 768px) {
  .cards {
    flex-wrap: wrap;
    > div {
     width: 50% 
    }
  }

  .cardDefault {
    background: #fff; 
    margin-bottom: 40px;
    .description {
      height: auto !important;
      opacity: 1;
      padding-bottom: 35px
    }
    .actions {
      opacity: 1;
      bottom: -20px;
    }
  }
  .ProductCard {
    margin-bottom: 15px;
    .actions {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
}