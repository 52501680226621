.mastheader {
  padding: 190px 0 120px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-color: #f4f4f5;

  .h2 {
    margin-top: 20px;
    font-size: 50px;
  }
  .span {
    font-size: 12px;
    line-height: 15px;
    display: block;
    color: #3f3b3b;
  }
}

.mastheader .mastheader_iphone {
  position: absolute;
  bottom: -310px;
  left: 50%;
  margin-left: -185px;
}

.mastheader .video-btn {
  margin-top: 48px;
}

.mastheader .video-btn i {
  margin-right: 15px;
}

.mastheader:after {
  content: '';
  display: block;
  width: 60%;
  height: 140%;
  position: absolute;
  background: #212c5f;
  top: -12%;
  right: -220px;
  z-index: 3;
  transform: rotate(13deg);
}

.mastheader:before {
  content: '';
  display: block;
  width: 63%;
  height: 140%;
  position: absolute;
  background: #c82343;
  top: 10%;
  right: -210px;
  z-index: 2;
  transform: rotate(18deg);
}


.flex-right {
  display: flex;
  justify-content: flex-end;
}

.whitelabel {
  min-width: 420px;
  background: #fff;
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.22);
  z-index: 4;
  position: relative;
  width: 448px;
  margin-top: 20px;
  
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .h4 {
      font-family: Lato;
      font-size: 20px;
      color: #303036;
    }
    .time {
      text-align: right;
    }
  }
}
.call {
  position: relative;
  z-index: 3;
}


.two-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    width: 100%;
    margin: 15px 5px 0;
  }
}

.actions {
  padding: 15px 5px;
}

.footer {
  padding-top: 10px;
  .security {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tagSecurity {
    display: flex;
    align-items: center;
    justify-content: center;
    .p {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 15px;
      font-weight: 600;
      font-family: Lato;
      color: #222d5e;
      margin: 0;
      margin-left: 5px;
    }
    .icon {
      width: 40px;
      height: 40px;
      background: #222d5e;
      border-radius: 40px;
      background: url('../Commons/sprite.png') 3px -99px;
    }
    
  }
}

.reserva {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.reserva .p {
  font-size: 16px;
  margin: 0;
  font-family: Lato;
}

.reserva .h4 {
  font-family: Lato;
  font-weight: 600;
  color: #625f5f;
}

.bg-header {
  width: 100%;
  position: absolute;
  height: 100%;
  background: url(./bg-header.svg) left bottom no-repeat;
  top: 0;
  background-size: 80%;
  opacity: .15;
  z-index: 0;
}

@media(max-width: 768px) {
  .whitelabel {
    min-width: 100%;
    width: 100%;
    margin-top: 25px;
  }
  .mastheader {
    padding: 130px 0 70px;

    .h2 {
      font-size: 35px;
      line-height: 40px
    }
    .p {
      font-size: 19px;
    }
  }
}

.topheader {
  .coins {
    background: #202c5f;
    overflow: hidden;
    position: relative;
    height: 40px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: block;
    align-items: center;
    width: 200%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    animation: marquee 15s linear infinite;
  }
  
  
  .item {
    float: left;
    width: 180px;
    color: #fff;
    padding: 5px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}


@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}